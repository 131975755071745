import * as i0 from '@angular/core';
import { EventEmitter, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
var OnlineStatusType;
(function (OnlineStatusType) {
  OnlineStatusType[OnlineStatusType["OFFLINE"] = 0] = "OFFLINE";
  OnlineStatusType[OnlineStatusType["ONLINE"] = 1] = "ONLINE";
})(OnlineStatusType || (OnlineStatusType = {}));
class OnlineStatusService {
  constructor() {
    this.status = new EventEmitter(true);
    this.bind();
    window.addEventListener(OnlineStatusService.EVENT_TYPE_ONLINE, this.onOnline);
    window.addEventListener(OnlineStatusService.EVENT_TYPE_OFFLINE, this.onOffline);
  }
  ngOnDestroy() {
    window.removeEventListener(OnlineStatusService.EVENT_TYPE_ONLINE, this.onOnline);
    window.removeEventListener(OnlineStatusService.EVENT_TYPE_OFFLINE, this.onOffline);
  }
  /**
   * getStatus - get online status
   *
   * @returns OnlineStatusType
   */
  getStatus() {
    if (navigator.onLine) {
      return OnlineStatusType.ONLINE;
    }
    return OnlineStatusType.OFFLINE;
  }
  bind() {
    this.onOnline = this.onOnline.bind(this);
    this.onOffline = this.onOffline.bind(this);
  }
  onOnline() {
    this.status.emit(OnlineStatusType.ONLINE);
  }
  onOffline() {
    this.status.emit(OnlineStatusType.OFFLINE);
  }
}
OnlineStatusService.EVENT_TYPE_ONLINE = 'online';
OnlineStatusService.EVENT_TYPE_OFFLINE = 'offline';
OnlineStatusService.ɵfac = function OnlineStatusService_Factory(t) {
  return new (t || OnlineStatusService)();
};
OnlineStatusService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: OnlineStatusService,
  factory: OnlineStatusService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlineStatusService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class OnlineStatusModule {}
OnlineStatusModule.ɵfac = function OnlineStatusModule_Factory(t) {
  return new (t || OnlineStatusModule)();
};
OnlineStatusModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OnlineStatusModule
});
OnlineStatusModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [OnlineStatusService],
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlineStatusModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [],
      providers: [OnlineStatusService]
    }]
  }], null, null);
})();

/**
 * Created by Vadym Yatsyuk on 27.01.18
 */

/**
 * Generated bundle index. Do not edit.
 */

export { OnlineStatusModule, OnlineStatusService, OnlineStatusType };
